import React from 'react'
import Header from './header'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, siteTitle, transparent}) => (
  <>
    <Header siteTitle={siteTitle} transparent={transparent} />
    <div className={styles.content}>{children}</div>
  </>
)

export default Layout
