import {Link} from 'gatsby'
import React from 'react'
import {cn} from '../lib/helpers'

import styles from './header.module.css'

const Header = ({siteTitle, transparent}) => (
  <div className={styles.root}>
    <div className={cn(styles.wrapper, transparent && styles.transparent)}>
      <nav className={styles.nav}>
        <ul>
          <h1>
            <Link to='/'>
              <svg
                width='200px'
                height='84px'
                className={styles.logo}
                viewBox='0 0 200 84'
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                role='image'
                title='Narwal Creative'
                aria-label='Narwal Creative'
              >
                <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                  <g id='Narwal-Logo-White' fill='#FFFFFF' fillRule='nonzero'>
                    <g id='Group'>
                      <path
                        d='M33.6,31.6 L33.6,51.9 L22.7,51.9 L22.7,34.2 C22.7,28.6 21,27 17.3,27 C13.6,27 11.1,29.6 11.1,34.8 L11.1,51.9 L0.2,51.9 L0.2,18 L11.1,18 L11.1,22 C13.8,18.9 17.5,17.2 21.4,17.2 C28.4,17.3 33.6,20.8 33.6,31.6 Z'
                        id='Path'
                      />
                      <path
                        d='M72,18 L72,52 L61.8,52 L61.8,48.3 L61.7,48.3 C59.5,51.1 56.3,52.6 52.3,52.6 C42.9,52.6 36.3,45.5 36.3,35 C36.3,24.6 43,17.3 51.9,17.3 C55.6,17.3 58.8,18.8 61.3,21.6 L61.4,21.6 L61.4,18 L72,18 Z M61.6,35 C61.6,30.3 58.5,26.9 54.4,26.9 C50.2,26.9 47.1,30.2 47.1,35 C47.1,39.8 50.1,43.1 54.3,43.1 C58.4,43 61.6,39.8 61.6,35 Z'
                        id='Shape'
                      />
                      <path
                        d='M97.7,17.3 L97.7,29 C96.7,28.9 95.8,28.9 95.2,28.9 C90.1,28.9 87.4,32.2 87.4,36.6 L87.4,51.9 L76.5,51.9 L76.5,18 L87.4,18 L87.4,22.6 L87.5,22.6 C90.1,19.1 93.6,17.3 97.4,17.3 C97.5,17.3 97.6,17.3 97.7,17.3 Z'
                        id='Path'
                      />
                      <polygon
                        id='Path'
                        points='150.7 18 139.2 52 130.2 52 124.9 33 124.8 33 119.7 52 110.6 52 99.1 18 109.9 18 115.5 36.2 115.6 36.2 120.6 18 129.1 18 134.2 36.3 134.3 36.3 139.9 18'
                      />
                      <path
                        d='M184.4,18 L184.4,52 L174.1,52 L174.1,48.3 L174,48.3 C171.8,51.1 168.6,52.6 164.6,52.6 C155.2,52.6 148.6,45.5 148.6,35 C148.6,24.6 155.3,17.3 164.2,17.3 C167.9,17.3 171.1,18.8 173.6,21.6 L173.7,21.6 L173.7,18 L184.4,18 Z M173.9,35 C173.9,30.3 170.8,26.9 166.7,26.9 C162.5,26.9 159.4,30.2 159.4,35 C159.4,39.8 162.4,43.1 166.6,43.1 C170.7,43 173.9,39.8 173.9,35 Z'
                        id='Shape'
                      />
                      <polygon id='Path' points='189.2 0.8 200 0.8 200 51.9 189.2 51.9' />
                    </g>
                  </g>
                </g>
              </svg>
            </Link>
          </h1>
          <div className={styles.listitems}>
            <li>
              <Link to='/about' activeClassName={styles.active}>
                about
              </Link>
            </li>
            <li>
              <Link to='/contact' activeClassName={styles.active}>
                contact
              </Link>
            </li>
          </div>
        </ul>
      </nav>
    </div>
  </div>
)

export default Header
